body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f5fe;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

 /* dashboard.js */
.dropdown-container {
  display: flex;
  align-items: center;
}

h5 {
  margin-right: 16px; /* Adjust spacing as needed */
}

.dropdowns {
  display: flex;
}

.dropdown {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
  margin-left: 8px; /* Spacing between dropdowns */
}

.dropdown.blue {
  background-color: rgb(0, 13, 128);
  color: white;
}

.dropdown.white {
  background-color: white;
  color: black;
}

.dropdown option {
  color: rgb(231, 231, 231); /* Ensure text color in dropdown options is readable */
}

.dropdown-month {
  margin-left: 30rem; /* Pushes the dropdown to the end right */
}